import Home from "./home";

const Dashboard = () => {


    return (
       <div>
           { <Home /> }
       </div>
    )
}

export default Dashboard;